import React from 'react';
import CenteredLayout from '../layout/layout-centered';
import computerphoneImg from '../../../static/img/assets/computerpreview.png';
import { Link, navigate } from 'gatsby';
import '../../../static/styles/_callout.scss';
import styled from 'styled-components';
import { Textfit } from 'react-textfit';

import CreateAccountButton from './common/createaccountbutton';

const CTAWrapper = styled.div`
  flex-direction: column;
  align-items: baseline !important;
  @media (max-width: 768px) {
    display: grid;
    justify-content: center;
  }
`;

const CTA = styled.h3`
  margin: 0;
  padding: 0;
  font-stretch: condensed;
  font-family: 'HelveticaNeue-CondensedBold';
  font-size: 30vw;
  font-weight: bold;
  color: white;
`;

const Button = styled.button`
  width: auto;
  padding: 0 2rem;
  margin-right: 0;
  -webkit-font-smoothing: auto;
  font-weight: normal;
  background: #e51670 !important;
  border: none;
  border-radius: 8px;
  font-size: 2rem;
  max-width: calc(100vw - 2rem);
  @media (max-width: 425px) {
    height: auto;
    padding: 1rem 2rem;
  }
`;

const Callimg = styled.img`
  max-width: 100%;
`;
const Wrapper = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  place-content: center center;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;

export default () => (
  <section className="callout">
    <span className="color-bg" />
    <CenteredLayout>
      <Wrapper className="ad">
        <CTAWrapper>
          <CTA mode="single">Always simple.</CTA>
          <CTA mode="single">Always secure.</CTA>
          <CTA mode="single">Always free.</CTA>
        </CTAWrapper>
        <div>
          <Callimg src={computerphoneImg} alt="screenshot" />
        </div>
      </Wrapper>
      <CreateAccountButton />
    </CenteredLayout>
  </section>
);
