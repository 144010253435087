import "../../../static/styles/home.scss";
import React from "react";
import { Helmet } from "react-helmet";
//layouts
import CenteredLayout from "../layout/layout-centered";
import Hero from "../home/hero";
import VideoDescription from "../home/video-description";
import Steps from "../home/steps";
import Callout from "../home/callout";
import Advantages from "../home/advantages";
import HomeFooter from "../home/home-footer";
import HeroBanner from "../home/herobanner";
import { useStaticQuery, graphql } from "gatsby";
import HomeVideoPoster from "../../../static/img/assets/homevideoposter.png";
import Slider from "../home/slider";
import styled from "styled-components";

const Button = styled.button`
  display: grid;
  width: max-content;
  margin: auto;
  padding: 0.5rem 4rem;
  justify-self: center;
  font-size: 2rem;
  -webkit-font-smoothing: auto;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: #e51670;
  border: none;
  border-radius: 6px;
  max-width: calc(100% - 2rem);
  min-height: 50px;
  @media (max-width: 600px) {
    height: auto;
  }
`;

export default props => {
  const data = useStaticQuery(graphql`
    {
      all: allFile(filter: { name: { eq: "home-anime" } }) {
        videos: edges {
          video: node {
            name
            relativePath
            publicURL
          }
        }
      }
      howto: allFile(filter: { name: { eq: "howtohubraisenew" } }) {
        videos: edges {
          video: node {
            name
            relativePath
            publicURL
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <Helmet>
        <title>GivHub - Fundraise and Support All Your Causes</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="user-scalable=no,initial-scale=1.0,maximum-scale=1.0"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Helmet>
      {props.children}
      <Hero />
      <HeroBanner />
      <CenteredLayout>{/* <IntroText /> */}</CenteredLayout>
      <Slider />
      <span className="home-wrapper">
        <VideoDescription
          title="How to Hubraise on GivHub"
          text="<p>Click or tap below to watch the video</p>"
          video={data.howto.videos[0].video.publicURL}
          poster={HomeVideoPoster}
        />
        <Steps />

        {/* <div className="an-wrapper">
          <div>
            <button
              onClick={() => {
                navigate("/closed-beta-notification?search-causes")
              }}
              className="searchcauses"
              style={{ marginRight: "40px" }}
            >
              SEARCH CAUSES
            </button>

          </div>*/}

        {/* <video
            crossOrigin="anonymous"
            loop
            autoPlay="autoplay"
            muted
            playsInline
          >
            <source src={data.all.videos[0].video.publicURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>*/}
        <Advantages />
        <Callout />
        <HomeFooter />
      </span>
    </React.Fragment>
  );
};
