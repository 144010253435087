import React from 'react';

const Survey = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <g>
          <g>
            <path
              d="M405.333,42.667h-44.632c-4.418-12.389-16.147-21.333-30.035-21.333h-32.219C288.427,8.042,272.677,0,256,0     s-32.427,8.042-42.448,21.333h-32.219c-13.888,0-25.617,8.944-30.035,21.333h-44.631C83.135,42.667,64,61.802,64,85.333v384     C64,492.865,83.135,512,106.667,512h298.667C428.865,512,448,492.865,448,469.333v-384C448,61.802,428.865,42.667,405.333,42.667     z M170.667,53.333c0-5.885,4.781-10.667,10.667-10.667h37.917c3.792,0,7.302-2.021,9.219-5.302     c5.844-10.042,16.135-16.031,27.531-16.031s21.688,5.99,27.531,16.031c1.917,3.281,5.427,5.302,9.219,5.302h37.917     c5.885,0,10.667,4.781,10.667,10.667V64c0,11.76-9.573,21.333-21.333,21.333H192c-11.76,0-21.333-9.573-21.333-21.333V53.333z      M426.667,469.333c0,11.76-9.573,21.333-21.333,21.333H106.667c-11.76,0-21.333-9.573-21.333-21.333v-384     c0-11.76,9.573-21.333,21.333-21.333h42.667c0,23.531,19.135,42.667,42.667,42.667h128c23.531,0,42.667-19.135,42.667-42.667     h42.667c11.76,0,21.333,9.573,21.333,21.333V469.333z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M181.333,170.667h-42.667c-5.896,0-10.667,4.771-10.667,10.667V224c0,5.896,4.771,10.667,10.667,10.667h42.667     c5.896,0,10.667-4.771,10.667-10.667v-42.667C192,175.438,187.229,170.667,181.333,170.667z M170.667,213.333h-21.333V192h21.333     V213.333z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M181.333,277.333h-42.667c-5.896,0-10.667,4.771-10.667,10.667v42.667c0,5.896,4.771,10.667,10.667,10.667h42.667     c5.896,0,10.667-4.771,10.667-10.667V288C192,282.104,187.229,277.333,181.333,277.333z M170.667,320h-21.333v-21.333h21.333V320     z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M181.333,384h-42.667c-5.896,0-10.667,4.771-10.667,10.667v42.667c0,5.896,4.771,10.667,10.667,10.667h42.667     c5.896,0,10.667-4.771,10.667-10.667v-42.667C192,388.771,187.229,384,181.333,384z M170.667,426.667h-21.333v-21.333h21.333     V426.667z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M252.875,195.125c-4.167-4.167-10.917-4.167-15.083,0l-3.125,3.125l-3.125-3.125c-4.167-4.167-10.917-4.167-15.083,0     c-4.167,4.167-4.167,10.917,0,15.083l3.125,3.125l-3.125,3.125c-4.167,4.167-4.167,10.917,0,15.083     c2.083,2.083,4.813,3.125,7.542,3.125s5.458-1.042,7.542-3.125l3.125-3.125l3.125,3.125c2.083,2.083,4.813,3.125,7.542,3.125     c2.729,0,5.458-1.042,7.542-3.125c4.167-4.167,4.167-10.917,0-15.083l-3.125-3.125l3.125-3.125     C257.042,206.042,257.042,199.292,252.875,195.125z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M316.875,195.125c-4.167-4.167-10.917-4.167-15.083,0l-3.125,3.125l-3.125-3.125c-4.167-4.167-10.917-4.167-15.083,0     c-4.167,4.167-4.167,10.917,0,15.083l3.125,3.125l-3.125,3.125c-4.167,4.167-4.167,10.917,0,15.083     c2.083,2.083,4.813,3.125,7.542,3.125s5.458-1.042,7.542-3.125l3.125-3.125l3.125,3.125c2.083,2.083,4.813,3.125,7.542,3.125     c2.729,0,5.458-1.042,7.542-3.125c4.167-4.167,4.167-10.917,0-15.083l-3.125-3.125l3.125-3.125     C321.042,206.042,321.042,199.292,316.875,195.125z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M252.875,301.792c-4.167-4.167-10.917-4.167-15.083,0l-3.125,3.125l-3.125-3.125c-4.167-4.167-10.917-4.167-15.083,0     c-4.167,4.167-4.167,10.917,0,15.083l3.125,3.125l-3.125,3.125c-4.167,4.167-4.167,10.917,0,15.083     c2.083,2.083,4.813,3.125,7.542,3.125s5.458-1.042,7.542-3.125l3.125-3.125l3.125,3.125c2.083,2.083,4.813,3.125,7.542,3.125     c2.729,0,5.458-1.042,7.542-3.125c4.167-4.167,4.167-10.917,0-15.083L249.75,320l3.125-3.125     C257.042,312.708,257.042,305.958,252.875,301.792z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M316.875,301.792c-4.167-4.167-10.917-4.167-15.083,0l-3.125,3.125l-3.125-3.125c-4.167-4.167-10.917-4.167-15.083,0     c-4.167,4.167-4.167,10.917,0,15.083l3.125,3.125l-3.125,3.125c-4.167,4.167-4.167,10.917,0,15.083     c2.083,2.083,4.813,3.125,7.542,3.125s5.458-1.042,7.542-3.125l3.125-3.125l3.125,3.125c2.083,2.083,4.813,3.125,7.542,3.125     c2.729,0,5.458-1.042,7.542-3.125c4.167-4.167,4.167-10.917,0-15.083L313.75,320l3.125-3.125     C321.042,312.708,321.042,305.958,316.875,301.792z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M252.875,408.458c-4.167-4.167-10.917-4.167-15.083,0l-3.125,3.125l-3.125-3.125c-4.167-4.167-10.917-4.167-15.083,0     c-4.167,4.167-4.167,10.917,0,15.083l3.125,3.125l-3.125,3.125c-4.167,4.167-4.167,10.917,0,15.083     c2.083,2.083,4.813,3.125,7.542,3.125s5.458-1.042,7.542-3.125l3.125-3.125l3.125,3.125c2.083,2.083,4.813,3.125,7.542,3.125     c2.729,0,5.458-1.042,7.542-3.125c4.167-4.167,4.167-10.917,0-15.083l-3.125-3.125l3.125-3.125     C257.042,419.375,257.042,412.625,252.875,408.458z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
            <path
              d="M380.875,195.125c-4.167-4.167-10.917-4.167-15.083,0l-3.125,3.125l-3.125-3.125c-4.167-4.167-10.917-4.167-15.083,0     c-4.167,4.167-4.167,10.917,0,15.083l3.125,3.125l-3.125,3.125c-4.167,4.167-4.167,10.917,0,15.083     c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l3.125-3.125l3.125,3.125     c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125c4.167-4.167,4.167-10.917,0-15.083l-3.125-3.125     l3.125-3.125C385.042,206.042,385.042,199.292,380.875,195.125z"
              data-original="#000000"
              class="active-path"
              data-old_color="#000000"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Survey;
