import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { navigate } from 'gatsby';
import Img1 from '../../../static/styles/images/img1.png';
import Img2 from '../../../static/styles/images/markFay.png';
import Img3 from '../../../static/styles/images/img3.png';
import Img4 from '../../../static/styles/images/img4.png';
import Img5 from '../../../static/styles/images/img5.png';
import Img6 from '../../../static/styles/images/img6.png';
import InvertedCommas from '../../../static/styles/icons/invertedCommas';
import CreateAccountButton from './common/createaccountbutton';
import Title from './common/title';

const marquee = keyframes`
  16% {
    transform: translateX(calc(-300px + 4% - 4%));
  }
  20% {
    transform: translateX(calc(-300px + 4% - 4%));
  }
  32% {
    transform: translateX(calc(-600px + 4% - 8%));
  }
  36% {
    transform: translateX(calc(-600px + 4% - 8%));
  }
  48% {
    transform: translateX(calc(-900px + 4% - 12%));
  }
  52% {
    transform: translateX(calc(-900px + 4% - 12%));
  }
  64% {
    transform: translateX(calc(-1200px + 4% - 16%));
  }
  68% {
    transform: translateX(calc(-1200px + 4% - 16%));
  }
  80% {
    transform: translateX(calc(-1500px + 4% - 20%));
  }
  84% {
    transform: translateX(calc(-1500px + 4% - 20%));
  }
  96% {
    transform: translateX(calc(-1800px + 4% - 24%));
  }
  100% {
    transform: translateX(calc(-1800px + 4% - 24%));
  }

`;

const Section = styled.section`
  margin: 10rem 0rem;
  font-family: 'HelveticaNeue';
`;

const PaddedDiv = styled.div`
  padding: 0 2rem;
`;
const Text = styled.p`
  max-width: 900px;
  margin: 0 auto 10rem;
  font-size: 2rem;
  line-height: 32px;
  text-align: center;
  color: #062e60;
`;

const Slider = styled.div`
  display: grid;
  margin-bottom: 10rem;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 4%;
  width: 100%;
  animation: ${marquee} 45s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  position: relative;
  transition: transform 1s linear;

  /* transform: ${props =>
    `translateX(calc(${props.width / 300 - (props.width % 300)}px  - ${2 * (props.width / 300)}% + 150px))`}; */
  &:hover {
    /* animation-play-state: paused; */
  }
`;

const Slide = styled.article`
  position: relative;
  max-width: 300px;
  padding: 2rem 2rem 2rem;
  background: #ffffff;
  box-shadow: 8px 10px 35px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const SlideText = styled.span`
  font-family: 'Times New Roman';
  font-size: 1.6rem;
  font-style: italic;
  text-align: center;
  color: #062e60;
  margin: 0;
  height: 150px;
  display: grid;
  place-content: center;
  overflow: hidden;
`;

const Author = styled.h3`
  font-size: 2rem;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.222222px;
  color: #062e60;
  margin: 0;
  margin-bottom: 5px;
`;

const Designation = styled.span`
  font-size: 1.2rem;
  line-height: 20px;
  text-align: center;
  color: #062e60;
  margin: 0;
  margin-bottom: 10px;
  display: block;
`;

const Img = styled.img``;

const InvertedCommasIcon = styled(InvertedCommas)`
  position: absolute;
  top: -35px;
  left: 40%;
  transform: translateY(20%);
  fill: #50d9e3;
  width: 60px;
`;

const InvertedCommasIconOrange = styled(InvertedCommasIcon)`
  fill: #ebb424;
`;

const InvertedCommasIconGreen = styled(InvertedCommasIcon)`
  fill: #49c39e;
`;
const list = [
  {
    text:
      '“After evaluating GivHub  I was blown away by this completely original fundraising platform, and GivHub’s unique philosophy of awarding entrepreneurial performance based college and trade school scholarships”',
    author: 'Gary Garcia',
    des: 'Senior Director, The Dallas Foundation',
    image: Img1,
    Comma: InvertedCommasIcon
  },
  {
    text:
      '“I know how hard it is to develop a good SaaS, and GivHub is both simple and powerful. From a single dashboard, each student can both publish and manage their fundraising campaign.”',
    author: 'Mark Fay',
    des: 'Paschal High School Tech Club Advisor',
    image: Img2,
    Comma: InvertedCommasIconOrange
  },
  {
    text:
      '“Heck yes I’m a Hubraiser! My GivHub story shows my fans what I really care about, and gives meaning to my social media presence that, let’s face it, is normally reserved for self-promotion.”',
    author: 'Wynn Williams',
    des: 'Country Music Recording Artist',
    image: Img3,
    Comma: InvertedCommasIconGreen
  },
  {
    text:
      '“We applaud GivHub’s innovative approach in rewarding students with scholarships for improving their communication skills, being creatively resourceful, and working hard to reach local chapter fundraising goals.”',
    author: 'Aaron Alejandro',
    des: 'Executive Director, Texas FFA Foundation',
    image: Img4,
    Comma: InvertedCommasIcon
  },
  {
    text:
      '“Fundraising with GivHub sure does beat selling cookie dough and discount cards. I could tell that my family was thankful to not have to buy something they didn’t really want!”',
    author: 'Cooper Adams',
    des: 'High School Football Player',
    image: Img5,
    Comma: InvertedCommasIconOrange
  },
  {
    text:
      '“GivHub works because it appeals to todays tech minded kids, and financially strengthens my districts clubs without piling on additional work and stress for my parents, teachers and advisors.”',
    author: 'Dr. Marcus Nelson',
    des: 'Educator & Superintendent',
    image: Img6,
    Comma: InvertedCommasIconGreen
  }
];

const SlideFac = (props, key) => (
  <Slide key={key}>
    <SlideText
      dangerouslySetInnerHTML={{
        __html: props.text.replace(/\u00a0/g, ' ')
      }}
    />
    <Author
      dangerouslySetInnerHTML={{
        __html: props.author
      }}
    />{' '}
    <Designation
      dangerouslySetInnerHTML={{
        __html: props.des
      }}
    />
     <Img src={props.image}></Img>
    <props.Comma />
  </Slide>
);

export default props => {
  // const { width } = useWindowDimensions();
  return (
    <Section>
      <PaddedDiv>
        <Title margin={'0 0 2rem 0'}>Who is GivHub Made For?</Title>
        <Text>
          Everyone who supports a cause… from students, coaches, teachers, advisors, directors, volunteers and
          supporters to business owners, athletes, musicians, actors, bloggers and vloggers!
        </Text>
      </PaddedDiv>
      <Slider>{[...list, ...list].map((o, i) => SlideFac(o, i))}</Slider>

      <CreateAccountButton />
    </Section>
  );
};

function getWindowDimensions(window) {
  if (window) {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
}

function useWindowDimensions() {
  let window = typeof window !== 'undefined' ? window : false;
  let windowDimensions, setWindowDimensions;
  useEffect(() => {
    [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(window));
    function handleResize() {
      setWindowDimensions(getWindowDimensions(window));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
