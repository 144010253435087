import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Button = styled.button`
  width: auto;
  max-width: calc(100vw - 2rem);
  margin: 10rem auto 0;
  padding: 0 2rem;
  margin-right: 0;
  background: #e51670 !important;
  border: none;
  border-radius: 8px;
  font-size: 2rem;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  @media (max-width: 425px) {
    margin: 6rem auto 0;
    padding: 0.5rem 2rem;
    height: auto;
    min-height: 50px;
    transition: margin 0.33s ease-in-out;
  }
`;

const LinkStyled = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  -webkit-font-smoothing: auto;
  font-weight: normal;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  place-content: center center;
`;

export default props => {
  return (
    <Wrapper>
      <Button>
        <LinkStyled to="/signup">CREATE YOUR FREE ACCOUNT</LinkStyled>
      </Button>
    </Wrapper>
  );
};
