import React from 'react';

import group1 from '../../../static/img/assets/photogroup1.png';
import group2 from '../../../static/img/assets/photogroup2.png';
import group3 from '../../../static/img/assets/photogroup3.png';

import { Link } from 'gatsby';
import '../../../static/styles/_callout.scss';
import styled from 'styled-components';
import CreateAccountButton from './common/createaccountbutton';
import Title from './common/title';

const CenteredLayout = styled.div`
  max-width: 1212px;
  margin: auto;
`;

const Section = styled.section`
  padding: 11.5rem 0 13rem;
  margin-top: 11.5rem;
  text-align: center;
  background: linear-gradient(0deg, rgba(253, 253, 253, 0.0001) 14.67%, rgba(0, 0, 0, 0.05) 95.31%), #ffffff;
  background-blend-mode: normal, multiply;
  mix-blend-mode: normal;
  font-family: 'HelveticaNeue', 'Helvetica Neue', 'HelveticaNeueRoman', 'HelveticaNeue-Roman', 'Helvetica Neue Roman',
    'TeXGyreHerosRegular', 'Helvetica', 'Tahoma', 'Geneva', 'Arial', sans-serif;
`;

const Block = styled.div`
  font-family: 'HelveticaNeue-Medium';
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 4rem;
  margin-bottom: 14rem;
  padding: 0 4rem;
  margin-top: 5rem;

  @media (max-width: 646px) {
    margin-bottom: 6rem;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    display: flex;
    padding: 0rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  display: grid;
  text-align: center;
  max-width: 100vw;
  @media (max-width: 646px) {
    margin-bottom: 2rem;
    padding: 0 4rem;
    justify-content: center;
  }

  @media (max-width: 414px) {
    padding: 0 2rem;
  }
`;

const BlockTitle = styled.h3`
  font-size: 4.8rem;
  line-height: 59px;
  text-align: center;
  color: #31acb4;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 425px) {
    font-size: 12vw;
  }
`;

const BlockText = styled.p`
  max-width: 475px;
  margin: 2rem auto;
  font-size: 2rem;
  text-align: left;
  line-height: 32px;
  color: #062e60;
  padding: 0rem;
  @media (max-width: 414px) {
    font-size: 1.8rem;
  }
`;

const List = styled.ul`
  margin: auto;
  text-align: left;
  padding: 0;
`;

const Item = styled.li`
  font-size: 2rem;
  color: #062e60;
  list-style-type: none;
  position: relative;
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  &::before {
    content: '\\2713';
    margin-right: 1rem;
    font-size: 3rem;
    font-weight: bold;
    color: #e51670;
  }
  @media (max-width: 414px) {
    font-size: 1.8rem;
  }
`;

const Images = styled.div`
  position: relative;
  display: flex;
  max-width: 100vw;
  object-fit: contain;
  img {
    max-width: 500px;
    width: calc(100% - 5rem);
    object-fit: contain;
  }
  @media (max-width: 1268px) {
    justify-content: center;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    place-content: center center;
    direction: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ImagesOrder = styled(Images)`
  @media (max-width: 768px) {
    order: 1;
  }
`;

const ImageWrapper = styled.div`
  margin: ${props => props.margin};
  max-width: 225px;
  max-height: 270px;
  padding: 1.2rem 1.2rem 6rem;
  background: #e1dfdf;
  transform: ${props => props.rotate || '1deg'};
  z-index: ${props => props.zIndex};

  @media (max-width: 646px) {
    margin: 4rem auto;
  }
`;

export default () => (
  <Section>
    <CenteredLayout>
      <Title margin={'0 0 3rem 0'}>With GivHub, Everybody Wins!</Title>
      <Block>
        <Content>
          <BlockTitle>CAUSES</BlockTitle>
          <BlockText>
            In response to the endless sea of “tools” in modern philanthropy, we’ve delivered a single, simple solution
            to giving and fundraising.
          </BlockText>
          <List>
            <Item>No bookkeeping or admin required</Item>
            <Item>Incentivize donor retention</Item>
            <Item>Generate exponential giving</Item>
          </List>
        </Content>
        <Images>
          <img src={group1} alt="group 1" />
        </Images>
      </Block>

      <Block>
        <ImagesOrder>
          <img src={group2} alt="group 2" />
        </ImagesOrder>
        <Content>
          <BlockTitle>DONORS</BlockTitle>
          <BlockText>
            As a thank you for giving to a Fundraiser, donors can enter for a chance to win prizes like a sweet ride,
            beachside getaway & more.
          </BlockText>
          <List>
            <Item>Better margins for causes</Item>
            <Item>Unique prize winning experience</Item>
            <Item>Tax-deductable receipts</Item>
          </List>
        </Content>
      </Block>

      <Block>
        <Content>
          <BlockTitle>FUNDRAISERS</BlockTitle>
          <BlockText>
            For working hard on behalf of a cause, fundraisers can earn scholarships, rewards, and unique experiences -
            or simply pay it forward.
          </BlockText>
          <List>
            <Item>No product to sell</Item>
            <Item>Custom donation page in 5 minutes</Item>
            <Item>Earn points by growing your network</Item>
          </List>
        </Content>
        <Images>
          <img src={group3} alt="group 3" />
        </Images>
      </Block>
      <CreateAccountButton />
    </CenteredLayout>
  </Section>
);
