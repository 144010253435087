import React, { useState } from "react";
import clean from "sanitize-html-react";
import { navigate } from "gatsby";
import { StyledPlayer, PlayButton } from "../dashboard/styles/shell-styles";
import "video-react/dist/video-react.css";
import styled from "styled-components";
import stepscomputercompPNG from "../../../static/img/assets/stepscomputercomp.png";
import PlayButtonPNG from "../../../static/styles/images/playbutton.png";
import CenteredLayout from "../layout/layout-centered";
import Title from "./common/title";
const Section = styled(CenteredLayout)`
  padding-top: 4rem;
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  background-color: #f6f6f6;
  background: linear-gradient(
      0deg,
      rgba(253, 253, 253, 0.00001) 14.67%,
      rgba(0, 0, 0, 0.082937) 95.31%
    ),
    #ffffff;
  /* background-blend-mode: normal, multiply;
  mix-blend-mode: normal; */
`;

const Text = styled.span`
  text-align: center;
  font-size: 1.4rem;
  color: #062e60;
  p {
    margin: 0;
    margin-top: 3rem;
    @media (max-width: 425px) {
      margin-top: 4rem;
    }
  }
`;

const PlayerWrapper = styled.div`
  max-width: 1374px;
  margin: auto;
  .video-react-big-play-button {
    visibility: hidden;
  }
`;

export default props => {
  const { title, text, video, videoendlink } = props;
  let [IsPlaying, setIsPlaying] = useState(false);
  let [player, setPlayer] = useState(false);
  return (
    <Section>
      <a name="video" href="#video"></a>
      <div className="des">
        <Title>{title}</Title>
        <Text
          dangerouslySetInnerHTML={{
            __html: clean(text)
          }}
        />
      </div>

      <PlayerWrapper>
        {/* <StyledImage src={stepscomputercompPNG} /> */}
        <StyledPlayer
          ref={s => setPlayer(s)}
          light={stepscomputercompPNG}
          poster={stepscomputercompPNG}
          crossOrigin="anonymous"
          playing={IsPlaying}
          controlbar={false}
          onPlay={e => {
            setIsPlaying(true);
          }}
          onEnded={e => {
            setIsPlaying(false);
            if (videoendlink) navigate(videoendlink);
            else {
              let video = e.target;
              video.pause();
              video.currentTime = 0;
              video.load();
            }
          }}
        >
          {!IsPlaying && (
            <PlayButton
              onClick={e => {
                e.persist();
                if (player) {
                  setIsPlaying(true);
                  player.play();
                }
              }}
              IsPlaying={true}
              src={PlayButtonPNG}
              alt="play button"
            />
          )}
          {/* <BigPlayButton position="center" /> */}
          {/* <ControlBar autoHide={true} /> */}
          <source
            style={{ outline: "0" }}
            crossOrigin="anonymous"
            src={video + "?no-cache=1"}
          />
        </StyledPlayer>
      </PlayerWrapper>
    </Section>
  );
};
