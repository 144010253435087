import React from 'react';
import CenteredLayout from '../layout/layout-centered';
import '../../../static/styles/_steps.scss';
import CreateAccountButton from './common/createaccountbutton';

export default () => (
  <section className="steps">
    <span className="bg" />
    <CenteredLayout>
      <div className="lines">
        <hr />
        <hr />
        <hr />
      </div>
      <ol className="steps">
        <li>
          <h4>BECOME A HUBRAISER</h4>
          <p>
            Create a GivHub personal account so that you can donate to, and fundraise for the causes and organizations
            you care about.
          </p>
        </li>
        <li>
          <h4>CREATE & PUBLISH CAMPAIGNS</h4>
          <p>
            From your dashboard, you can add, edit and publish campaign donation pages by GivHub, or create your own
            campaign.
          </p>
        </li>
        <li>
          <h4>GROW YOUR NETWORK</h4>
          <p>
            Invite friends & family to give to your campaign, or fundraise for their own worthy cause. Working together
            can return big rewards.
          </p>
        </li>
        <li>
          <h4>OPTIMIZE YOUR FUNDRAISING</h4>
          <p>
            Connect your social accounts, promote your campaign, create events & forms, publish your GivMore Store and
            access reports.
          </p>
        </li>
      </ol>
    </CenteredLayout>
    <CreateAccountButton />
  </section>
);
