import React from 'react';
import CenteredLayout from '../layout/layout-centered';
import '../../../static/styles/herobanner.scss';
import styled from 'styled-components';
import Survey from '../../../static/styles/icons/survey.js';
import fingerSnapimg from '../../../static/img/assets/fingersnap.png';
import ticket from '../../../static/img/assets/ticket.png';
import clipboard from '../../../static/img/assets/clipboard.png';

const BottomBar = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 110px;
  @media (max-width: 768px) {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 100%;
    height: auto;
  }
`;
const Tab = styled.div`
  font-weight: bold;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.6px;
  color: #ffffff;
  min-width: 33%;
  display: grid;
  place-content: center;
  padding: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: auto;
  }
  > div {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    grid-gap: 1rem;
    justify-content: center;
  }
  h3 {
    font-size: 2.6rem;
    font-family: 'HelveticaNeue-CondensedBold';
    padding: 2rem 0;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 5.7vw;
    }
    @media (max-width: 320px) {
      font-size: 5.4vw;
    }
  }
  img {
    width: 100%;
    object-fit: contain;
    padding-right: 0;
    max-width: 40px;
    max-height: 40px;
  }
  @media (max-width: 1400px) {
    font-size: 2.6rem;
  }
`;

const TabOne = styled(Tab)`
  background: #50d9e3;
`;

const TabTwo = styled(Tab)`
  background: #2cc4ce;
  img {
    filter: invert(1);
  }
`;

const TabTree = styled(Tab)`
  background: #31acb4;
`;

const SurveyIcon = styled(Survey)`
  width: 60px;
  height: 60px;
  margin-right: 2rem;
  @media (max-width: 1400px) {
    width: 50px;
    height: 50px;
  }
`;

export default () => (
  <>
    <section className="herobanner">
      <CenteredLayout>
        <h2>We've taken the fragmented world of philanthropy and delivered a single, simple solution</h2>
      </CenteredLayout>
    </section>
    <BottomBar>
      <TabOne>
        <div>
          <img src={fingerSnapimg} />
          <h3>Free, all-in-one platform</h3>
        </div>
      </TabOne>
      <TabTwo>
        <div>
          <img src={ticket} />
          <h3>Built for fundraisers & donors</h3>
        </div>
      </TabTwo>
      <TabTree>
        <div>
          <img src={clipboard} />
          <h3>Simple tools for leaders</h3>
        </div>{' '}
      </TabTree>
    </BottomBar>
  </>
);
