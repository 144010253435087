import React, { Component } from "react";
import "../../../static/styles/hero-alt.scss";

export default class Hero extends Component {
  render() {
    return (
      <>
        <section className={"alt-hero"}>
          <h2 className="tal-left ">
            Smarter Giving. <br /> Smarter Fundraising.
          </h2>
          <h2 className="tal-right ">
            Always Simple, Secure <br /> and Free to use
          </h2>
        </section>
      </>
    );
  }
}
