import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Title = styled.h2`
  font-family: 'Helvetica Neue', HelveticaNeue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  font-size: ${props => props.fontSize || '4.2rem'};
  text-align: center;
  color: ${props => props.color || '#062e60'};
  margin: ${props => props.margin || '0'};
  @media (max-width: 1024px) {
    font-size: 6vw;
  }
  @media (max-width: 600px) {
    font-size: 11.5vw;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  place-content: center center;
  margin: 0 auto;
  max-width: calc(100vw - 2rem);
`;

export default ({ children, ...rest }) => {
  return (
    <Wrapper>
      <Title {...rest}>{children}</Title>
    </Wrapper>
  );
};
