import styled from "styled-components";
import { Swipeable } from "react-swipeable";
import { TextField, InputAdornment } from "@material-ui/core";
import { Link } from "gatsby";
import Modal from "@material-ui/core/Modal";
import { Player, BigPlayButton as bb } from "video-react";
import "video-react/dist/video-react.css";

export const BigPlayButton = bb;

export const PlayButton = styled.img`
  position: absolute;
  top: calc(50% - 50px);
  z-index: 10;
  width: 100px;
  left: calc(50% - 50px);
  transition: transform 0.5s linear;
  transform: scale(0.7);
  will-change: transform;
`;

export const PlayButtonWrapper = styled.div`
  display: grid;
  place-content: center center;
  position: relative;
  &:active,
  &:hover {
    ${PlayButton} {
      transform: scale(1);
    }
  }
`;

export const StyledPlayer = styled(Player)`
  outline: 0;
  position: relative;
  /* display: none; */
  .video-react-big-play-button-center {
    visibility: hidden;
  }
  .video-react-control-bar {
    /* visibility: hidden; */
  }
  &:active,
  &:hover {
    img {
      transform: scale(0.6);
    }
  }
`;
export const StyledModal = styled(Modal)`
  display: grid;
  place-content: center center;
  > div.stage {
    max-width: 1200px;
    width: calc(100vw - 4rem);
    outline: 0;
    &.vertical {
      max-width: 400px;
      max-height: calc(100vh);
    }
    @media (max-width: 990px) {
      min-width: 100vw;
    }
  }
`;

export const StepsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: auto;
  width: 100%;
  ol {
    counter-reset: fancy-counter;
    padding: 0;
    margin: 0;
    li {
      display: grid;
      grid-template-columns: 50px 1fr;
      list-style: none;
      align-items: center;
      grid-gap: 1rem;
      margin-bottom: 2.5rem;
      counter-increment: fancy-counter;
      > div {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        > div {
          display: grid;
          grid-template-columns: 90px 200px;
          grid-gap: 1rem;
        }
      }
      h4 {
        font-family: "HelveticaNeue";
        margin-bottom: 0rem;
      }
      &:nth-of-type(1)::before {
        background: #49c39e;
        color: #fff;
      }
      &:nth-of-type(2)::before {
        background: #1264a3;
        color: #fff;
      }
      &:nth-of-type(3)::before {
        background: #ebb424;
        color: #fff;
      }
      &:nth-of-type(4)::before {
        background: #e51670;
        color: #fff;
      }
      &::before {
        border-radius: 100%;
        content: counter(fancy-counter);
        display: flex;
        font-size: 2.4rem;
        line-height: 0.6;
        margin: 0 auto;
        min-width: 35px;
        min-height: 35px;
        max-width: 50px;
        max-height: 50px;
        text-align: center;
        margin-top: -0.6rem;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
export const CardLink = styled(Link)`
  text-transform: uppercase;
  color: #4a4a4a;
  -webkit-text-stroke-color: #979797;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 0px);
  overflow: hidden;
`;

export const LogoutLI = styled.li`
  width: 100%;
  display: grid !important;
  grid-template-columns: 100px;
  place-content: center center;
  top: 0;
  height: 35px;
  justify-items: flex-end;
  padding-right: 10px;
  a {
    /* display: grid; */
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0px 0px;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-flex;
  }
  @media (max-width: 500px) {
    right: 2.4%;
  }
`;
export const LogoutButton = styled(Link)`
  color: #1264a3;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  width: auto;
  a {
    margin: 0;
    padding: 0;
  }
`;

export const ScaledDiv = styled.div`
  transform: scale(0.25);
  transform-origin: top left;
  position: relative;
  width: 400px;
  height: 150px;
  display: flex;
  flex: 1;
  canvas {
    position: absolute;
  }
`;

export const Content = styled(Swipeable)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100vh;
  background: #efefef;
  align-content: flex-start;
  height: auto;
  overflow: auto;
  min-width: 100vw;
  overscroll-behavior-y: contain;
  &.open {
  }
  &.closed {
  }
`;

export const SecondMenu = styled.div`
  min-width: 255px;
  max-width: 100%;
  background-color: #002048;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 1000000;
  cursor: pointer;
  position: sticky;
  top: 50px;
  overscroll-behavior-y: contain;
`;

export const SecondMenuHeader = styled.div`
  color: white;
  width: 100%;
  padding: 0rem 1.6rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2rem;
  position: sticky;
  top: 0;
  background: #002048;
  z-index: 10;
  padding-bottom: 4.1rem;
`;

export const OrgName = styled.h2`
  font-weight: 600;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  text-transform: capitalize;
  flex: 1 1 75%;
  margin-bottom: 0;
`;

export const Points = styled.span`
  font-size: 1.2rem;
  color: #ffffff;
  margin: 0;
  text-transform: capitalize;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
  margin-top: 0.5rem;
  width: 100%;
  a {
    color: #fff;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    font-weight: 600;
    width: 100%;
    justify-content: space-evenly;
  }
`;

export const NewOrgName = styled.h5`
  font-weight: 600;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: capitalize;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-top: 7px;
`;

export const MenuName = styled.h3`
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  margin: 0;
`;

export const MenuNameTitle = styled.h4`
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  margin: 0;
`;

export const MenuHolder = styled.ol`
  margin-top: 0px;
  font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
    "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
    "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  font-size: 14px;
  color: rgb(255, 255, 255);
  background-color: #002048;
  list-style: none;
  padding: 0;
  hr {
    width: calc(100% - 4rem);
    margin: 2rem;
  }
  transition: transform 0.22s linear;
`;

export const PaddedDiv = styled.div`
  padding: ${props => (props.padding || 2) + "rem;"}
  display: block;
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.row ? "row" : "column")};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  align-items: baseline;
  &.dialog{
    max-width:400px;
    width:400px;
    h4 {
      font-size:1.8rem;
      margin-bottom:0;
    }
    p {
      font-size:1.4rem;
    }
    .MuiDialogActions-root{
      margin-top:2rem;
      align-self: flex-end;
    }
  }
`;

export const MenuItem = styled.li`
  &:hover {
    filter: brightness(0.7);
  }
  &.active {
    background: #1264a3;
    filter: brightness(1);
    &:hover {
      /* background: inherit; */
    }
    svg {
      transform: rotate(180deg);
      transition: transform 0.22s linear;
    }
    ul {
      transform: translateX(0);
      list-style: none;
      display: flex;
      height: auto;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      padding-bottom: 2rem;
      opacity: 1;
      font-weight: 400;
      will-change: transform;
      li {
        font-weight: 00;
        font-size: 1.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 2.6rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        position: relative;
        &.active {
          background: #1264a3;
        }
        &::after {
          content: "";
          /* border-left: 0.5rem solid #1264a3; */
          left: 0rem;
          top: 0;
          position: absolute;
          height: 100%;
        }
        &:hover {
          filter: brightness(0.7);
        }
      }
    }
  }
  ul {
    transform: translateX(-100%);
    will-change: transform;
    height: 0;
    opacity: 0;
    transition: transform 0.22s linear 0.22s, height 0s linear 0.44s;
  }
`;

export const MenuLink = styled.span`
  color: #ffffff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  flex: 1 1 100%;
  font-size: 1.5rem;
  justify-content: space-between;
  font-weight: 500;
  &.active {
    /* border-bottom: 1px solid #efefef55; */
  }
  svg,
  img {
    height: 100%;
  }
`;

export const AdCard = styled.div`
  width: 270px;
  min-width: auto;
  height: 60px;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  display: none;
  flex-direction: row;
  margin-right: 2rem;
  img {
    width: 60px;
    height: 60px;
    display: flex;
  }
  span {
    display: flex;
    padding: 1rem;
  }
  p {
    display: flex;
    height: 60px;
    flex-direction: column;
    margin: 0;
    font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
      "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
      "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
    font-size: 10px;
    strong {
      padding-bottom: 0.5rem;
    }
  }
`;
export const Drawer = styled(Swipeable)`
  display: flex;
  transition: transform 0.5s linear 0s;
  transform: translateX(-255px);
  flex-grow: 1;
  will-change: transform;
  &.open {
    transform: translateX(0px);
  }
`;

export const HorizontalMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  list-style: none;
  margin: 0;

  padding: 0;
  position: sticky;
  text-decoration: none;
  top: 0px;
  transition: all 0.22s linear 0s, min-width 0s linear;
  z-index: 11;
  width: calc(100vw - 81px);
  padding-left: 1rem;
  font-family: HelveticaNeue-Medium;
  will-change: transform;
  height: 30px;
  overflow: hidden;
  @media (max-width: 500px) {
    min-width: 100vw;
  }
  &.open {
    transform: translate(-255px, 0rem);
    min-width: calc(100vw - 80px);
  }

  li {
    color: #002048;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
      "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
      "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    justify-content: right;
    padding-right: 2.5rem;
    text-align: center;
    z-index: 2;
    align-items: center;
    &:first {
      padding-left: 0rem;
    }
    &.h-menu-header {
      align-items: center;
      background: #fff;
      display: flex;
      flex-direction: row;
      font-size: 1.4rem;
      font-weight: 400;
      justify-content: left;
      opacity: 1;
      transition: opacity 0s linear 0s, width 1s;
      will-change: opacity;
      max-width: 250px;
      max-height: 30px;
      div {
        div {
          @media (max-width: 500px) {
            width: 250px;
            max-width: 67vw;
            display: block;
            white-space: nowrap;
            word-spacing: inherit;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
          }
        }
      }
      & div {
        font-size: 1.4rem;
      }
      &.open {
        max-width: 255px;
        width: 255px;
        transition: width 1s;
      }
      svg {
        height: 25px;
        width: 25px;
      }
    }

    &.active {
      border-color: #ebb424;
    }
  }
`;

export const ProfileCircle = styled.img`
  width: 200px;
  height: 200px;
  border: 1px solid #fff;
  border-radius: 50%;
`;
export const NoProfileCircle = styled.div`
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  border-radius: 50%;
  display: grid;
  place-content: center;
  font-size: 3rem;
  color: #000;
  background: #dedede;
  a {
    text-decoration: none;
  }
`;

export const ContentCardWrapper = styled.div`
  gap: 2rem;
  grid-row-gap: 0;
  grid-template-columns: 1fr;
  display: grid;
  max-width: 100%;
  max-width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  padding-bottom: 4rem;
  height: inherit;
  will-change: max-width;
  max-width: ${props =>
    props.open ? "calc(100vw - 9.4rem - 24rem)" : "calc(100vw - 9.4rem)"};
  max-width: ${props =>
    `calc(${props.screenwidth}px - ${
      props.open ? "9.4rem - 24rem" : "9.4rem"
    })`}
  max-height: calc(100vh - 0px);
  margin-bottom: 4rem;

  @media (max-width: 500px) {
    justify-content: center;
    /* padding: 0.75rem 2rem;  */
    max-width: 100vw;
  }

`;

export const ContentCardColumn = styled.div`
  break-inside: avoid;
  max-width: 100vw;
  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const ContentCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.246632);
  height: max-content;
  margin-right: 2rem;
  margin-bottom: 2rem;
  /* min-width: 330px; */
  padding: ${props => (props.padding ? `${props.padding}rem` : "2rem")};
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  border: 0.5px solid #9c9c9c;
  overflow: hidden;
  max-width: 365px;

  @media (max-width: 500px) {
    margin-right: 0;
    width: calc(100vw - 20px);
    max-width: 480px;
  }
  table {
    border-collapse: collapse;
    font-size: 1.4rem;
    margin-top: 2rem;
    width: 100%;
    &.sticky {
      position: sticky;
      top: 0px;
    }
    thead {
      padding-bottom: 1rem;
      border-bottom: 0.1rem solid #d5d5d5;
    }

    tr {
      border-top: 0.1rem solid #d5d5d5;
      text-align: left;
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: 0.5rem 2rem;
      color: #4a4a4a;
      td {
        width: 50%;
        padding: 5px 0;
        &:nth-of-type(2) {
          text-align: right;
        }
        &:nth-child(odd) {
          min-width: 225px;
        }
      }
      &:first-child {
        border-top: none;
      }
    }
  }
  &.loading {
    display: flex;
    flex-direction: column;
    width: 480px;
    height: max-content;
    background: #fff;
    img {
      background: #fff;
      padding: 2rem;
    }
    p {
      height: 100%;
      width: 100%;
      font-weight: 600;
    }
  }
  h3 {
    background-color: #fff;
    border-radius: 4px;
    color: #003c6a;
    display: grid;
    font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman",
      "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular",
      "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    margin: 0;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    z-index: 10;
  }
  > img {
    margin: -2rem 0 2rem -2rem;
    max-width: calc(100% + 4rem);
  }
  > div {
    h4 {
      font-family: "HelveticaNeue";
      font-size: 2rem;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #1264a3;
      margin: 0.5rem 0 2rem 0;
    }
    img {
      max-width: 100%;
    }
    p,
    label {
      font-family: "HelveticaNeue";
      font-size: 1.5rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      color: #4a4a4a;
      line-height: 2.2rem;
      letter-spacing: 0.2px;
    }
  }
  form {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    &.center {
      justify-content: center;
    }
    label {
      margin-right: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      &.short {
        width: 80%;
      }
    }
    input {
      background: #fafafa;
      border: 1px solid #9b9b9b;
      padding: 5px;
      font-size: 1.5rem;
      width: 49%;
      border-radius: 5px;
      margin-bottom: 1rem;
      &.cal {
        width: 100%;
      }
    }
    input[type="radio"] {
      width: auto;
      height: 100%;
      -webkit-appearance: radio;
    }
    input.w100 {
      width: 100%;
      display: block;
    }
    button:not(.react-datepicker__navigation--next),
    button:not(.react-datepicker__navigation) {
      width: 100%;
      height: 30px;
      border: 0;
      border-radius: 4px;
      background-color: #ebb424;
      text-transform: uppercase;
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }
`;

export const ContentVideoCard = styled(ContentCard)`
  height: auto;
  @media (max-width: 500px) {
    margin-right: 0;
  }
  h3 {
    border-radius: 4px;
    display: grid;
    font-family: "HelveticaNeue";
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    margin: 0;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    z-index: 10;
    background: transparent;
  }
  > div {
    h4 {
      font-family: "HelveticaNeue";
      font-size: 2rem;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      margin: 1rem 0 2rem 0;
      display: flex;
      align-items: center;
    }
    img {
      max-width: 100%;
    }
  }
`;

export const ContentCardButton = styled.button`
  background: #e51670;
  border: none;
  display: block;
  font-size: 1.4rem;
  height: 3.5rem;
  max-width: 180px;
  margin: 2rem auto 1rem;
  width: 100%;
`;

export const FSet = styled.fieldset`
  margin-bottom: 1rem !important;
  color: #4a4a4a;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: 100%;
  padding: 1rem;
  div {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  legend {
    padding: 0 0.5rem;
  }
`;
export const Holder = styled.div`
  font-size: 1.4rem;
  flex: 0 0 35%;
  justify-content: space-around;
  display: flex;
  color: #4a4a4a;
  cursor: pointer;
  svg {
    font-size: 2rem;
  }
`;

export const Avatar = styled(ProfileCircle)`
  width: 40px;
  height: 40px;
  margin: 9px 9px 0 0;
`;

export const AmountInput = styled(TextField)`
  display: block;
  margin: 0;
  margin-bottom: 2rem;
`;
export const inputCharLimit = 150;
export const InputPara = styled(TextField)`
  width: 100%;
  margin: 0rem;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  position: relative;
  &::after {
    content: ${props => `'${props.cornercontent}/${inputCharLimit}'`};
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: ${props =>
      props.cornercontent > inputCharLimit - 2 ? "red" : "#888"};
    font-size: 0.8rem;
  }
`;
export const SliderHolder = styled.div`
  width: 100%;
  padding: 0rem 2rem;
  display: flex;
  flex-direction: row;
  margin: 1rem 0rem;
  flex: 1 1 100%;
  align-items: flex-start;
  label {
    flex-basis: 20%;
    svg {
      margin-left: 0.5rem;
    }
  }
  span {
    flex-basis: 100%;
    &.MuiSlider-root {
      color: #979797;
    }
  }
`;

export const FormWrapper = styled.form`
  margin-top: 2rem !important;
  &.center {
    justify-content: center !important;
  }
  label,
  p {
    width: auto !important;
    margin: 0;
    padding: 0;
  }

  input {
    font-size: inherit;
    width: inherit;
    border-radius: inherit;
    border: 0 !important;
    margin: 0 !important;
    padding: 2rem 1rem !important;
    font-size: 2rem;
  }
`;

export const Form = styled.form`
  width: 90%;
  margin: 0 auto;
`;

export const FormDivider = styled.div`
  width: 100%;
  height: 7px;
  margin: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  p {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    background-color: white;
    width: 120px;
    margin: 0 auto;
  }
`;

export const NeumorphicButton = styled.button`
  width: 100%;
  height: 40px;
  background-color: #1264a3;
  border: none;
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.25),
    -2px -2px 3px 0 rgba(255, 255, 255, 0.3);
  &:hover {
    background-color: #0f5991;
  }
  &:active,
  &.active {
    background-color: transparent;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.25) inset,
      -2px -2px 3px 0 rgba(255, 255, 255, 0.3) inset;
  }
`;
