import React, { useState } from 'react';
import CenteredLayout from '../layout/layout-centered';
import Logo from '../../../static/img/branding/givhub-logo.svg';
import FBlogo from '../../../static/img/footer-section/social/fb.png';
import Instagramlogo from '../../../static/img/footer-section/social/instagram.png';
import Twitterlogo from '../../../static/img/footer-section/social/twitter.png';
import Linkedlogo from '../../../static/img/footer-section/social/linkedin.png';
import axios from 'axios';
import { Link } from 'gatsby';
export default ({ short = false }) => {
  let [messageObj, setMessaage] = useState({});
  return (
    <>
      {!short && (
        <section className="footer">
          <CenteredLayout>
            <div className="sec1">
              <h6 className="list-heading">Corporate Info</h6>
              <address>
                <p>5049 Edwards Ranch Rd, 4th Floor</p>
                <p>Fort Worth, TX 76109, United States</p>
                <a href="tel:1-800-475-5498" className="tel">
                  (800) 475-5498
                </a>
                <a href="mailto:info@mygivhub.org">info@mygivhub.org</a>
              </address>
              <ul className="social">
                <li className="fb">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/mygivhub.org/">
                    <img src={FBlogo} alt="facebook" />
                  </a>
                </li>
                <li className="insta">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/mygivhub/">
                    <img src={Instagramlogo} alt="instagram" />
                  </a>
                </li>
                <li className="twiiter">
                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/mygivhub">
                    <img src={Twitterlogo} alt="twitter" />
                  </a>
                </li>
                <li className="linkedin">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/givhub">
                    <img src={Linkedlogo} alt="linkedin" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="sec2">
              <h6 className="list-heading">Quick Links</h6>

              <ul>
                <li>
                  <Link to="/login/">Sign In </Link>
                </li>
                <li>
                  {/* <Link to="/signup/"> Create Account</Link> */}
                  <Link to="//closed-beta-notification?create-account/">Create Account</Link>
                </li>
                <li>
                  <Link to="/closed-beta-notification?search-cause-categories">Search Organizations </Link>
                </li>

                {/* <li>
                  <Link to="/closed-beta-notification?blog">Blog</Link>
                </li> */}
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
                {/* <li>
                  <Link to="/closed-beta-notification?career-opportunities">Career Opportunities </Link>
                </li> */}
              </ul>
            </div>
            <div className="sec3">
              <h6>Send Us a Message</h6>
              <form
                onSubmit={async e => {
                  e.preventDefault();
                  console.log(messageObj);
                  let api = 'https://api.mygivhub.org';
                  await axios.post(api + '/send-message', messageObj);
                  alert('Message Sent!');
                  setMessaage({});
                }}
              >
                <input
                  required
                  autoComplete="cc-name"
                  type="text"
                  value={messageObj.name}
                  placeholder="First and Last Name"
                  onChange={({ currentTarget }) => {
                    let v = currentTarget.value;
                    let newOb = {
                      ...messageObj,
                      ...{
                        name: v
                      }
                    };
                    setMessaage(newOb);
                  }}
                />
                <input
                  value={messageObj.email}
                  required
                  autoComplete="email"
                  type="email"
                  placeholder="Email Address"
                  onChange={({ currentTarget }) => {
                    let v = currentTarget.value;
                    let newOb = {
                      ...messageObj,
                      ...{
                        email: v
                      }
                    };
                    setMessaage(newOb);
                  }}
                />
                <textarea
                  onChange={({ currentTarget }) => {
                    let v = currentTarget.value;
                    let newOb = {
                      ...messageObj,
                      ...{
                        message: v
                      }
                    };
                    setMessaage(newOb);
                  }}
                  value={messageObj.message}
                  required
                  placeholder="Enter Message Here"
                />
                <input type="submit" value="Send" />
              </form>
            </div>
          </CenteredLayout>
        </section>
      )}
      <footer className="final-footer">
        <CenteredLayout>
          <span className="left">
            <small>Copyright © 2018 GivHub. All rights reserved.</small>
          </span>
          <span className="center">
            <Link to="/">
              <img src={Logo} alt="givhub logo" />
            </Link>
          </span>
          <span className="right">
            <small>
              <Link to="/terms">Terms of Use</Link>
            </small>
            <small>
              <Link to="/privacy">Privacy Policy </Link>
            </small>
          </span>
        </CenteredLayout>
      </footer>
    </>
  );
};
